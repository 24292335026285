<template>
  <div
    class="views_shopping_mail_teaching_package_details bbox"
  >
    <div class="summary_group bbox d-flex align-center justify-space-between"
      v-if="ppackage"
    >
      <div class="info_group bbox d-flex align-stretch">
        <div class="picture_group flex-shrink-0">
          <img
            v-if="ppackage.webpic"
            :src="`${$img_url}${ppackage.webpic}`"
            alt=""
            class="picture"
            ref="img"
            :style="{height: `${calc_height}px`}"
          >
          <div
            v-if="!ppackage.webpic"
            class="no_picture_group un_sel bbox d-flex align-center justify-center"
            ref="no_picture"
            :style="{height: calc_height?`${calc_height}px`:'100%'}"
          >
            <div class="pic_title">
              {{ ppackage.title }}
            </div>
          </div>
          <div class="category pt-10"
            v-if="0"
          >会展专业 / 会展营销 / 市场营销</div>
          <div class="data_group pt-10 d-flex align-center">
            <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon mr-4">
            <div class="text pr-20">{{$moment(ppackage.createTime).format('YYYY年MM月DD日')}}</div>
            <template
              v-if="0"
            >
              <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
              <div class="text pr-20">9876</div>
              <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
              <div class="text">9.2w</div>
            </template>
          </div>
        </div>
        <div class="details_group d-flex flex-column bbox align-start">
          <div class="title flex-shrink-0">{{ppackage.title}}</div>
          <div class="sub_title flex-shrink-0 pt-10">简介：</div>
          <div class="details pt-6"
            v-html="ppackage.content"
          ></div>
        </div>
      </div>
      <div class="payment_group flex-shrink-0 ml-20">
        <div class="price"><span class="text">售价：</span><span class="number">¥{{ppackage.price/100}}</span></div>
        <div class="hint pt-14"
          v-if="ppackage.accountnum && ppackage.accountnum !== 1"
        >包含绑定账户数：1-{{ppackage.accountnum}}个</div>
        <div class="payment mt-20 cp un_sel"
          @click.stop="doConsulting();"
        >
          {{ ppackage.price === 0 ? '免费领取' : '咨询购买' }}
        </div>
      </div>
    </div>
    <div class="task_list_group d-flex align-center flex-wrap mt-20"
      v-if="task_list && task_list.length"
    >
      <template
        v-for="(item,index) in task_list"
      >
        <task-module
          class="mr-16 mb-16"
          :key="index"
          :ptask="item"
          @click.stop.native="viewTask(item)"
        ></task-module>
      </template>
    </div>
    <div class="section_title my-20">讲师信息</div>
    <div class="lecturer_group d-flex align-center flex-wrap"
      v-if="ppackage"
    >
      <template
        v-for="(item, index) in ppackage.users"
      >
        <div
          :key="index"
          class="unit cp mr-10"
        >
          <div class="avatar_group">
            <img
              :src="`${$avatar_url}${item.thumbnail}@!small200`"
              alt=""
              class="avatar"
            >
            <div
              v-if="0"
              class="mask d-flex align-center justify-center"
            >
              查看讲师>
            </div>
          </div>
          <div
            class="name mt-10"
          >
            {{ item.userName }}
          </div>
        </div>
      </template>
    </div>
    <div class="section"
      v-if="0"
    >
      <title-module
        class="section_title_group"
        title="相关推荐"
      ></title-module>
      <div class="list_group d-flex align-start flex-wrap">
        <template
          v-for="item in 10"
        >
          <teaching-package
            class="teaching_package mb-10"
            :key="item"
            v-if="item<=5"
          ></teaching-package>
        </template>
      </div>
    </div>
    <div class="nomore">————  已经到底啦  ————</div>
  </div>
</template>

<script>
import TaskModule from '@/views/ShoppingMail/Modules/Task/Unit/Major.vue';
import TitleModule from '@/views/ShoppingMail/Modules/Common/Title.vue';
import TeachingPackage from '@/views/ShoppingMail/Modules/TeachingPackage/Unit/Major.vue';
import {PPackage, PTask} from '@/types';
export default {
  components: {
    TaskModule,
    TitleModule,
    TeachingPackage,
  },
  props: {
    package_id: {
      type: Number,
      default: undefined,
    },
    popTask: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      ppackage: undefined,
      task_list: [],
      calc_height: undefined,
    }
  },
  async mounted(){
    await this.init();
  },
  methods: {
    async init(){
      this.getTaskList();
      await this.getData();
      this.$nextTick(async function(){
        this.getLecturerShopData();
      })
    },
    initSize(){
      // const {clientWidth} = this.$refs.img;
      let clientWidth
      if (this.$refs.img) {
        clientWidth = this.$refs.img.clientWidth
      }
      if (this.$refs.no_picture) {
        clientWidth = this.$refs.no_picture.clientWidth
      }
      const calc_height = this.calculativeHeight({width: clientWidth});
      this.calc_height = calc_height;
    },
    /**
     * 按照16:9转换高度
     */
    calculativeHeight(params={}){
      const {width} = params;
      const height = (9 * width) / 16;
      return height;
    },
    async getData(){
      const res = await this.$api.teaching_package.getData({id: this.package_id});
      const {message, contents} = res;
      if(message === 'success'){
        const {ppackage} = contents;
        this.$set(this, 'ppackage', new PPackage(ppackage));
      }

      this.$nextTick(function(){
        this.initSize();
      })
      return contents;
    },
    async getTaskList(){
      const res = await this.$api.teaching_package.getTaskList({id: this.package_id});
      const {message, contents} = res;
      if(message === 'success'){
        const {taskList} = contents;
        const task_list = this.$types.transformObjectForList({
          list: taskList,
          obj : PTask,
        })
        this.$set(this, 'task_list', task_list);
      }
      return contents;
    },
    /**
     * 教学包
     */
    async getLecturerShopData(){
      const res = await this.ppackage.getLecturerShopData();
      // if(res){
      //   // const {packagesall, packageshot, packagesnew, tasksall, taskshot, tasksnew} = res;
      // }
    },
    /**
     * 教学包预览任务
     */
    viewTask(item){
      if (this.popTask) {
        this.$api.moduleTask.openTask({
          taskId: item.id,
          config: {
            view: true,
            // share: true,
            // view: true,
            // teach: true,
          }})
        return
      }
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true,})
    },
    doConsulting(){
      const { ppackage } = this
      const { price } = ppackage
      let obj =  ppackage
      if(obj.buy){
        this.$notice({desc: '您已购买过此课程'})
        return
      }
      if(price === 0){
        const res = obj.getFree()
        if(res){
          this.$notice({desc: '领取成功', tpye: 'success'})
        }
        return
      }
      this.$consulting({
        title: '咨询购买',
        subtitle: '您好，请确认并填写以下信息，方便工作人员与您及时联系。',
        enquireType: 0,
        paramId: this.package_id,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_teaching_package_details{
  width  : 100%;
  padding: {
    left : 40px;
    right: 40px;
  }
  .summary_group{
    padding         : 30px;
    background-color: #fff;
    .info_group{
      flex: 1;
    }
    .picture_group{
      margin-right: 30px;
      width       : 30%;
      max-width   : 416px;
    }
    .picture{
      width: 100%;
      // max-width: 416px;
      // height: 234px;
      display      : block;
      border-radius: 4px;
    }
    .no_picture_group{
      width: 100%;
      //display: block;
      border-radius: 4px;
      background-color: #aaa;
      .pic_title{
        font-size: 18px;
        color: #fff;
        width: 100%;
        padding: 0 10px;
        text-align: center;
        word-break: break-all;
      }
    }
    .category{
      font-size  : 12px;
      color      : #AAAAAA;
      line-height: 16px;
    }
    .info_group{
      .data_group{
        font-size  : 12px;
        color      : #AAAAAA;
        line-height: 16px;
      }
    }
    .details_group{
      flex: 1;
      .title{
        font-size  : 26px;
        font-weight: bold;
        color      : #333333;
        line-height: 35px;
      }
      .sub_title{
        font-size  : 14px;
        color      : #333333;
        line-height: 19px;
      }
      .details{
        @include scrollbar;
        overflow-y : auto;
        flex       : 1;
        width      : 100%;
        font-size  : 14px;
        color      : #666666;
        line-height: 26px;
        max-height : 26*7px;
        word-break : break-all;
      }
    }
    .payment_group{
      .price{
        color         : #F3580C;
        font-size     : 30px;
        font-weight   : bold;
        vertical-align: baseline;
        .number{
          font-size  : 36px;
          text-shadow: 0px 2px 4px rgba(243, 88, 12, 0.5);
        }
      }
      .hint{
        font-size  : 18px;
        color      : #666666;
        line-height: 24px;
      }
      .payment{
        color        : #fff;
        padding      : 0 60px;
        line-height  : 48px;
        background   : #2BB06C;
        border-radius: 4px;
        text-align   : center;
      }
    }
  }
  .task_list_group{

  }
  .section_title{
    font-size  : 22px;
    color      : #000000;
    line-height: 29px;
  }
  .lecturer_group{
    .unit{
      &:hover{
        .mask{
          opacity: 1;
        }
      }
    }
    .avatar_group{
      position: relative;
    }
    .mask{
      @include transition;
      opacity         : 0;
      position        : absolute;
      width           : 100%;
      height          : 100%;
      top             : 0;
      left            : 0;
      background-color: rgba(0,0,0,0.6);
      font-size       : 14px;
      color           : #fff;
      border-radius   : 50%;
    }
    .avatar{
      width        : 90px;
      height       : 90px;
      border-radius: 50%;
      display      : block;
    }
    .name{
      @include single_line_intercept;
      width      : 90px;
      font-size  : 16px;
      color      : #333333;
      line-height: 21px;
      text-align : center;
    }
  }
  // .section{
  //   padding: 0 40px;
  // }
  .section_title_group{
    padding-top: 30px;
  }
  .list_group{
    padding: 30px 0 40px;
  }
  .teaching_package{
    width       : 18.9%;
    margin-right: 1.32%;
    &:nth-of-type(5n){
      margin-right: 0;
    }
  }
  .nomore{
    width      : 100%;
    font-size  : 16px;
    color      : #D5D9DB;
    line-height: 21px;
    text-align : center;
    padding    : 50px 0;
  }
}
</style>
