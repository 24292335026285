var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_teaching_package_details bbox" },
    [
      _vm.ppackage
        ? _c(
            "div",
            {
              staticClass:
                "summary_group bbox d-flex align-center justify-space-between",
            },
            [
              _c(
                "div",
                { staticClass: "info_group bbox d-flex align-stretch" },
                [
                  _c("div", { staticClass: "picture_group flex-shrink-0" }, [
                    _vm.ppackage.webpic
                      ? _c("img", {
                          ref: "img",
                          staticClass: "picture",
                          style: { height: `${_vm.calc_height}px` },
                          attrs: {
                            src: `${_vm.$img_url}${_vm.ppackage.webpic}`,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    !_vm.ppackage.webpic
                      ? _c(
                          "div",
                          {
                            ref: "no_picture",
                            staticClass:
                              "no_picture_group un_sel bbox d-flex align-center justify-center",
                            style: {
                              height: _vm.calc_height
                                ? `${_vm.calc_height}px`
                                : "100%",
                            },
                          },
                          [
                            _c("div", { staticClass: "pic_title" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.ppackage.title) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    0
                      ? _c("div", { staticClass: "category pt-10" }, [
                          _vm._v("会展专业 / 会展营销 / 市场营销"),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "data_group pt-10 d-flex align-center" },
                      [
                        _c("img", {
                          staticClass: "icon mr-4",
                          attrs: {
                            src: require("@/assets/images/shopping_mail/time_icon.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "text pr-20" }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(_vm.ppackage.createTime)
                                .format("YYYY年MM月DD日")
                            )
                          ),
                        ]),
                        0
                          ? [
                              _c("img", {
                                staticClass: "icon mr-4",
                                attrs: {
                                  src: require("@/assets/images/shopping_mail/sale_icon.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text pr-20" }, [
                                _vm._v("9876"),
                              ]),
                              _c("img", {
                                staticClass: "icon mr-4",
                                attrs: {
                                  src: require("@/assets/images/shopping_mail/view_icon.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("9.2w"),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "details_group d-flex flex-column bbox align-start",
                    },
                    [
                      _c("div", { staticClass: "title flex-shrink-0" }, [
                        _vm._v(_vm._s(_vm.ppackage.title)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "sub_title flex-shrink-0 pt-10" },
                        [_vm._v("简介：")]
                      ),
                      _c("div", {
                        staticClass: "details pt-6",
                        domProps: { innerHTML: _vm._s(_vm.ppackage.content) },
                      }),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "payment_group flex-shrink-0 ml-20" }, [
                _c("div", { staticClass: "price" }, [
                  _c("span", { staticClass: "text" }, [_vm._v("售价：")]),
                  _c("span", { staticClass: "number" }, [
                    _vm._v("¥" + _vm._s(_vm.ppackage.price / 100)),
                  ]),
                ]),
                _vm.ppackage.accountnum && _vm.ppackage.accountnum !== 1
                  ? _c("div", { staticClass: "hint pt-14" }, [
                      _vm._v(
                        "包含绑定账户数：1-" +
                          _vm._s(_vm.ppackage.accountnum) +
                          "个"
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "payment mt-20 cp un_sel",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.doConsulting()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.ppackage.price === 0 ? "免费领取" : "咨询购买"
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.task_list && _vm.task_list.length
        ? _c(
            "div",
            {
              staticClass:
                "task_list_group d-flex align-center flex-wrap mt-20",
            },
            [
              _vm._l(_vm.task_list, function (item, index) {
                return [
                  _c("task-module", {
                    key: index,
                    staticClass: "mr-16 mb-16",
                    attrs: { ptask: item },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.viewTask(item)
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "section_title my-20" }, [_vm._v("讲师信息")]),
      _vm.ppackage
        ? _c(
            "div",
            { staticClass: "lecturer_group d-flex align-center flex-wrap" },
            [
              _vm._l(_vm.ppackage.users, function (item, index) {
                return [
                  _c("div", { key: index, staticClass: "unit cp mr-10" }, [
                    _c("div", { staticClass: "avatar_group" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                          alt: "",
                        },
                      }),
                      0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mask d-flex align-center justify-center",
                            },
                            [_vm._v("\n            查看讲师>\n          ")]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "name mt-10" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.userName) + "\n        "
                      ),
                    ]),
                  ]),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      0
        ? _c(
            "div",
            { staticClass: "section" },
            [
              _c("title-module", {
                staticClass: "section_title_group",
                attrs: { title: "相关推荐" },
              }),
              _c(
                "div",
                { staticClass: "list_group d-flex align-start flex-wrap" },
                [
                  _vm._l(10, function (item) {
                    return [
                      item <= 5
                        ? _c("teaching-package", {
                            key: item,
                            staticClass: "teaching_package mb-10",
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "nomore" }, [_vm._v("————  已经到底啦  ————")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }